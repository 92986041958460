import { io } from "socket.io-client";
import globalneZmienne from "../globalneZmienne";
import { useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { BsDot } from "react-icons/bs";
let socket = io.connect(globalneZmienne.adresBackend);

export default function Czat(props){
	const [ czat, setCzat ] = useState({conn: false, historia: [], wysylana: '', zerwano: false});

	/* TODO:
	- zapisywanie historii czatu do bazy danych
	- zrobic endpointy socketu dla czatu ktory nie jest globalny
	*/

	useEffect(() => {
		socket.on("connect", () => {
			console.log("Połączono WS");
			if(props.id == "globalny"){
				socket.emit("dolaczCzatGlobalny", {
					login: localStorage.getItem("login")
				});
			} else {
				socket.emit("dolaczCzat", {
					idpokoju: props.id,
					login: localStorage.getItem("login")
				});
			}
		});
		
		socket.on("dolaczonoCzatGlobalny", (arg) => {
			console.log("Dołączono do czatu globalnego");
			setCzat({...czat, historia: arg, conn: true, zerwano: false});
		});

		socket.on("disconnect", (r) => {
			console.log("Rozłączył", r);
			setCzat({...czat, conn: false, zerwano: true});
			socket.connect();
		});

		socket.on("odbierzCzat", (arg) => {
			console.log(arg);
			setCzat({...czat, historia: arg, conn: true});
		});
	}, []);

	const wyslijWiadomosc = () => {
		if(!czat.wysylana || !czat.conn){
			return;
		}
		if(props.id == "globalny"){
			socket.emit("wyslijCzatGlobalny", {
				login: localStorage.getItem("login"),
				awatar: localStorage.getItem("awatar"),
				wiadomosc: czat.wysylana
			});
		} else {
			socket.emit("wyslijCzat", {
				idpokoju: props.id,
				login: localStorage.getItem("login"),
				awatar: localStorage.getItem("awatar"),
				wiadomosc: czat.wysylana
			});
		}
		setCzat({...czat, wysylana:''});
	};

	const zawartoscCzatu = () => {
		if(!czat.conn || czat.zerwano){
			if(!czat.zerwano){
				if(socket.connected){
					//jak ktos byl na glownej ale poszedl na inna podstrone i wrocil na glowna
					return(
						<>
							<span>Zerwano połączenie</span>
							<br/><button className="odnowCzat" onClick={() => polaczCzat()}>Połącz</button>
						</>
					);
				} else {
					//inicjalny komunikat po "świeżym" wejściu na główną
					return <span>Trwa łączenie</span>
				}
			} else {
				//jak np. scrashuje backend
				return(
					<>
						<span>Zerwano połączenie</span>
						<br/><button className="odnowCzat" onClick={() => polaczCzat()}>Połącz</button>
					</>
				);
			}
		}
		return czat.historia.map((wiadomosc) => {
			return(
				<div key={`${wiadomosc.login}_${wiadomosc.kiedy}`} className={`czatWiadomosc ${wiadomosc.login === localStorage.getItem('login') && "czatTwojaWiadomosc"}`}>
					<div className="czatWiadomoscContainer">
						<div className="czatWiadomoscAwatar" style={{background: `url(${wiadomosc.awatar})`}} onClick={() => {window.location.href = `/profil/${wiadomosc.login}`}}/>
						<div className="czatWiadomoscHeader"><a href={`/profil/${wiadomosc.login}`} >{wiadomosc.login}</a> <BsDot /> {new Date(wiadomosc.kiedy).toLocaleString('pl-PL', {day: 'numeric', month: 'long'})} {new Date(wiadomosc.kiedy).toLocaleString('pl-PL', {hour: '2-digit', minute: '2-digit'}) }</div>
						<div className="czatWiadomoscZawartosc">{wiadomosc.wiadomosc}</div>
					</div>
				</div>
			);
		})
	};

	const polaczCzat = () => {
		/* ehh, to chyba nie zadziała, lepiej forsownie odświeżyć strone po prostu xd
		socket.connect();
		console.log("Status ws", socket.connected); */
		window.location.reload();
	}

    return(<>
        <div className="czat">
			<div className="tytulCzatu">{props.tytul}</div>
			<div className="czatWiadomosci">
				{ czat.historia && zawartoscCzatu() }
			</div>
			<div className="czatWysylanie">
				<input type="text" placeholder="Twoja wiadomość" value={czat.wysylana ? czat.wysylana : ''} onChange={(e) => setCzat({...czat, wysylana: e.target.value})} onKeyDown={(e) => { 
					    if(e.key === 'Enter') { e.preventDefault(); wyslijWiadomosc(); }
					}}
                />
				<button onClick={() => wyslijWiadomosc()}><IoIosSend /></button>
			</div>
		</div>
    </>);
}