import { NavLink, Navigate } from "react-router-dom";
import { useState } from "react";
import Axios from "axios";
import globalneZmienne from "../globalneZmienne.js";

export default function Logowanie(props){
    const [daneFormularza, setDaneFormularza] = useState({login: undefined, haslo: undefined, blad: undefined});

    const zaloguj = () => {
        if(!daneFormularza.login){
            setDaneFormularza({...daneFormularza, blad: "Podaj login!"});
            return;
        }
        if(!daneFormularza.haslo){
            setDaneFormularza({...daneFormularza, blad: "Podaj hasło!"});
            return;
        }
        if(daneFormularza.login.length < 5 || daneFormularza.login.length > 40){
            setDaneFormularza({...daneFormularza, blad: "Niepoprawna długość loginu!"});
            return;
        }
        if(daneFormularza.haslo.length < 6 || daneFormularza.haslo.length > 40){
            setDaneFormularza({...daneFormularza, blad: "Niepoprawna długość hasła!"});
            return;
        }
        Axios.post(globalneZmienne.adresBackend+"zaloguj", {login: daneFormularza.login, haslo: daneFormularza.haslo})
        .then((r) => {
            if(!r.data['blad']){
                localStorage.setItem("token", r.data['token']);
                localStorage.setItem("login", r.data['login']);
                localStorage.setItem("awatar", "/img/"+r.data['awatar']);
                window.location.href = "/";
            } else {
                setDaneFormularza({...daneFormularza, blad: r.data['blad']});
            }
        }).catch((er) => {
            setDaneFormularza({...daneFormularza, blad: er.message});
        })
    };

    return(
        <>
            <div className="logowanieOkno">
                <span>Login</span>
                <input type="text" placeholder="Użytkownik" value={daneFormularza.login ? daneFormularza.login : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, login: e.target.value})}/>
                <span>Hasło</span>
                <input type="password" placeholder="Hasło" onKeyDown={(e) => { 
						    if(e.key === 'Enter') { e.preventDefault(); zaloguj(); }
						}}
                        value={daneFormularza.haslo ? daneFormularza.haslo : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, haslo: e.target.value})}/>
                <button onClick={() => zaloguj()}>Zaloguj</button>
                {daneFormularza.blad && <p>{daneFormularza.blad}</p>}
                <NavLink to="/resethasla">Resetuj hasło</NavLink>
                <div className="poziomaKreska" />
                <span>Nie masz konta?</span>
                <button onClick={() => { window.location.href="./rejestracja" }}>Zarejestruj</button>
            </div>
        </>
    )
};