import {
	Route,
	BrowserRouter as Router,
	Routes,
	Navigate,
} from "react-router-dom";

import Logowanie from "./Strony/Logowanie";
import Rejestracja from "./Strony/Rejestracja";
import ResetHasla from "./Strony/ResetHasla";
import Glowna from "./Strony/Glowna";
import Pusta from "./Strony/Pusta";
import Brudnopis from "./Strony/Brudnopis";
import Rozgrywka from "./Strony/Rozgrywka";
import Profil from "./Strony/Profil";
import BrudnopisTest from "./Strony/BrudnopisTest";

export default function Aplikacja() {
	const czyMaToken = localStorage.getItem("token") ? localStorage.getItem("token") : false;
    return (
        <Router>
            <Routes>
                <Route path="/" element={czyMaToken ? <Glowna /> : <Navigate to="/zaloguj" />} exact/>
                <Route path="/zaloguj" element={czyMaToken ? <Navigate to="/" /> : <Logowanie />} />
                <Route path="/rejestracja" element={czyMaToken ? <Navigate to="/" /> : <Rejestracja />} />
                <Route path="/resethasla" element={czyMaToken ? <Navigate to="/"/> : <ResetHasla/>} />
                <Route path="/brudnopis" element={czyMaToken ? <Brudnopis /> : <Navigate to="/zaloguj" /> } />
                <Route path="/brudnopistest" element={czyMaToken ? <BrudnopisTest /> : <Navigate to="/zaloguj" /> } />
                {/*<Route path="/pokoj/:identyfikatorPokoju" element={czyMaToken ? <Rozgrywka /> : <Navigate to="/zaloguj" />} /> */}
                <Route path="/rozgrywka/:kodPokoju" element={czyMaToken ? <Rozgrywka /> : <Navigate to="/zaloguj" /> } />
                <Route path="/rozgrywka" element={czyMaToken ? <Rozgrywka /> : <Navigate to="/zaloguj" /> } />
                <Route path="/profil" element={czyMaToken ? <Profil /> : <Navigate to="/zaloguj" /> } />
                <Route path="/profil/:uzytkownik" element={czyMaToken ? <Profil /> : <Navigate to="/zaloguj" /> } />
                <Route path="*" element={<Pusta />} />
            </Routes>
        </Router>
    );
};