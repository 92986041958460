import { useState, useRef, useEffect } from "react";
import { IoTrashBin } from "react-icons/io5";
import { RiPaintFill } from "react-icons/ri";
import { HiPaintBrush } from "react-icons/hi2";
import { io } from "socket.io-client";
import globalneZmienne from "../globalneZmienne";

let socket = io.connect(globalneZmienne.adresBackend);
export default function Rysowanie(props){
	const [aktWspolrzedne, setAktWspolrzedne] = useState({x: -1, y: -1});
	const [ opcje, setOpcje ] = useState({poprzedniX: null, poprzedniY: null, ksztalt: "kropka", kolor: "#000000", kolor2: "#ffffff", grubosc: 6, tryb: 'pedzel'});
	const [ relatywny, setRelatywny ] = useState([]);
	const [ wysunGrubosc, setWysunGrubosc ] = useState(false);
	const czyWcisniety = useRef(false);
	const ktoryKolor = useRef(0);
	const kartka = useRef(null);
	const kartkaCur = useRef(null);

    useEffect(() => {
        socket.on("connect", () => {
			console.log("Połączono WS rysowanie");
            socket.emit("dolaczRysowanie", {idpokoju: props.idpokoju, kto: localStorage.getItem("login")});
		});
        socket.on("dolaczonoRysowanie", (arg) => {
            console.log(arg.kto, "dołączył do rysowania pokoju: ", arg.idpokoju);
        });
		socket.on("disconnect", (r) => {
			console.log("Rozłączono od rysowania", r);
			socket.connect();
		});
		socket.on("odbierzRysowanie", (arg) => {
			rysujKszaltOdebrany(arg.x, arg.y, arg.prevX, arg.prevY, arg.kolor, arg.grubosc);
		});
        socket.on("odbierzWypelnijObszar", (arg) => {
            wypelnijObszar(arg.x, arg.y, arg.kolor, kartka.current.getContext('2d', { willReadFrequently: true }));
        });
        socket.on("odbierzCzyscCanvas", (arg) => {
            odbierzCzyscCanvas();
        });
    }, []);

    const ustawReferencje = (ref) => {
		if(!ref || kartka.current){ return }
		kartka.current = ref;
		const rect = kartka.current.getBoundingClientRect();
		kartka.current.width = rect.width;
		kartka.current.height = rect.height;
		czyscCanvas();
	};
	
	const ustawReferencjeGen = (ref) => {
		if(!ref || kartkaCur.current){ return }
		kartkaCur.current = ref;
		generujNowyKursor(null, null);
	};

	const polozenieMyszy = (e) => {
		if(!props.rysujacy) return;
		const wspolrzedneKartki = kartka.current.getBoundingClientRect();
		const poprawneX = (e.clientX - wspolrzedneKartki.left) - (e.clientX - wspolrzedneKartki.left)%1;
		const poprawneY = (e.clientY - wspolrzedneKartki.top) - (e.clientY - wspolrzedneKartki.top)%1;
		setAktWspolrzedne({x: poprawneX, y: poprawneY});
		if(czyWcisniety.current && opcje.tryb == "pedzel"){ rysujKszalt(poprawneX, poprawneY); }
		!relatywny.length && setOpcje({...opcje, poprzedniX: poprawneX, poprzedniY: poprawneY});
	};

	const wypelnijObszar = (startX, startY, ustawianyKol, ctx) => {
		const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
		const kolorCelu = dostanKolorPixel(startX, startY, imageData);

		if (kolorCelu.toLowerCase() === ustawianyKol.toLowerCase()) {
			console.log("Wypełniasz obszar kolorem w którym już ten obszar jest głuptasie...");
			return;
		}
	
		const stos = [{ x: startX, y: startY }];
		while (stos.length > 0) {
			const { x, y } = stos.pop();
			if (x < 0 || x >= ctx.canvas.width || y < 0 || y >= ctx.canvas.height) continue;
			if (dostanKolorPixel(x, y, imageData).toLowerCase() === kolorCelu.toLowerCase()) {
				ustawKolorPixel(x, y, ustawianyKol, imageData);
				stos.push({ x: x + 1, y: y });
				stos.push({ x: x - 1, y: y });
				stos.push({ x: x, y: y + 1 });
				stos.push({ x: x, y: y - 1 });
			}
		}

		ctx.putImageData(imageData, 0, 0);
	}

	const dostanKolorPixel = (x, y, imageData) => {
		const offset = (y * imageData.width + x) * 4;
		return (
			"#" +
			("00" + imageData.data[offset].toString(16)).slice(-2) +
			("00" + imageData.data[offset + 1].toString(16)).slice(-2) +
			("00" + imageData.data[offset + 2].toString(16)).slice(-2)
		);
	};
	
	const ustawKolorPixel = (x, y, fillColor, imageData) => {
		const offset = (y * imageData.width + x) * 4;
		const hexColor = fillColor.slice(1);
		imageData.data[offset] = parseInt(hexColor.substring(0, 2), 16);
		imageData.data[offset + 1] = parseInt(hexColor.substring(2, 4), 16);
		imageData.data[offset + 2] = parseInt(hexColor.substring(4, 6), 16);
	};

	const fWcisniety = (e) => {
		if(!props.rysujacy) return;
		czyWcisniety.current = true;
		ktoryKolor.current = e.button;
		if(opcje.tryb == "wiaderko"){
			wypelnijObszar(aktWspolrzedne.x, aktWspolrzedne.y, ktoryKolor.current ? opcje.kolor2 : opcje.kolor, kartka.current.getContext('2d', { willReadFrequently: true }));
            socket.emit("wypelnijObszar", {idpokoju: props.idpokoju, kto: localStorage.getItem("login"), x: aktWspolrzedne.x, y: aktWspolrzedne.y, kolor: ktoryKolor.current ? opcje.kolor2 : opcje.kolor});
		}
	};

	const fPuszczony = (e) => {	czyWcisniety.current = false; };

	const rysujKszalt = (x, y) => {
		const ctx = kartka.current.getContext('2d', { willReadFrequently: true });
		ctx.beginPath();
		ctx.globalAlpha = 1;
		ctx.globalCompositeOperation = 'source-over';

		ctx.strokeStyle = ktoryKolor.current ? opcje.kolor2 : opcje.kolor;
		ctx.lineWidth = opcje.grubosc;
		ctx.moveTo(opcje.poprzedniX ? opcje.poprzedniX : x, opcje.poprzedniY ? opcje.poprzedniY : y);
		ctx.lineTo(x, y);
		ctx.stroke();

		ctx.beginPath();
		ctx.fillStyle = ktoryKolor.current ? opcje.kolor2 : opcje.kolor;
		ctx.lineWidth = opcje.grubosc/Math.PI;
		ctx.arc(x, y, opcje.grubosc/2.2, 0, Math.PI*2);
		ctx.fill();

		setRelatywny([]);
        if(props.idpokoju && props.rysujacy){
            socket.emit("rysuj", {idpokoju: props.idpokoju, kto: localStorage.getItem("login"), x: x, y: y, prevX: opcje.poprzedniX, prevY: opcje.poprzedniY, kolor: ktoryKolor.current ? opcje.kolor2 : opcje.kolor, grubosc: opcje.grubosc});
        }
	};

    const rysujKszaltOdebrany = (x, y, prevX, prevY, kolor, grubosc) => {
        const ctx = kartka.current.getContext('2d', { willReadFrequently: true });
		ctx.beginPath();
		ctx.globalAlpha = 1;
		ctx.globalCompositeOperation = 'source-over';

		ctx.strokeStyle = kolor;
		ctx.lineWidth = grubosc;
		ctx.moveTo(prevX ? prevX : x, prevY ? prevY : y);
		ctx.lineTo(x, y);
		ctx.stroke();

		ctx.beginPath();
		ctx.fillStyle = kolor;
		ctx.lineWidth = grubosc/Math.PI;
		ctx.arc(x, y, grubosc/2.2, 0, Math.PI*2);
		ctx.fill();

		setRelatywny([]);
    };

    const odbierzCzyscCanvas = () => {
		let ctx = kartka.current.getContext('2d');
		ctx.fillStyle = "#FFFFFF";
		ctx.fillRect(0,0,kartka.current.width, kartka.current.height);
    };

	const czyscCanvas = () => {
		let ctx = kartka.current.getContext('2d');
		ctx.fillStyle = "#FFFFFF";
		ctx.fillRect(0,0,kartka.current.width, kartka.current.height);
        if(props.idpokoju && props.rysujacy){
            socket.emit("czyscCanvas", {idpokoju: props.idpokoju, kto: localStorage.getItem("login")});
        }
	};

	const dostepneGrubosci = () => {
		return(
			<div className="wysunGrubosci">
				<div className="wyborGrubosci2" onClick={() => { zmienGrubosc(2); setWysunGrubosc(!wysunGrubosc)}} >
					<div className="gruboscPedzla" style={{width: 2*1.35, height: 2*1.3}} />
				</div>
				<div className="wyborGrubosci2" onClick={() => { zmienGrubosc(6); setWysunGrubosc(!wysunGrubosc)}} >
					<div className="gruboscPedzla" style={{width: 6*1.2, height: 6*1.15}} />
				</div>
				<div className="wyborGrubosci2" onClick={() => { zmienGrubosc(10); setWysunGrubosc(!wysunGrubosc)}}>
					<div className="gruboscPedzla" style={{width: 10*1.25, height: 10*1.2}} />
				</div>
				<div className="wyborGrubosci2" onClick={() => { zmienGrubosc(14); setWysunGrubosc(!wysunGrubosc)}}>
					<div className="gruboscPedzla" style={{width: 14*1.25, height: 14*1.2}} />
				</div>
				<div className="wyborGrubosci2" onClick={() => { zmienGrubosc(18); setWysunGrubosc(!wysunGrubosc)}}>
					<div className="gruboscPedzla" style={{width: 18*1.25, height: 18*1.2}} />
				</div>
			</div>
		)
	};

	const zmienGrubosc = (grubosc) => {
		setOpcje({...opcje, grubosc: grubosc});
		generujNowyKursor(null, grubosc);
	}

	const zmienPedzel = (kolor, prawy) => {
		if(prawy) setOpcje({...opcje, kolor2: kolor})
		else setOpcje({...opcje, kolor: kolor});
		generujNowyKursor(kolor, null);
	}
	
	const generujNowyKursor = (kolor, grubosc) => {
		let ctx = kartkaCur.current.getContext('2d');
		let tmpGrub = grubosc ? grubosc : opcje.grubosc;
		let tmpKol = kolor ? kolor : opcje.kolor;
		kartkaCur.current.style.width = tmpGrub * 1.25;
		kartkaCur.current.style.height = tmpGrub * 1.2;
		kartkaCur.current.width = tmpGrub * 1.25;
		kartkaCur.current.height = tmpGrub * 1.2;
		ctx.clearRect(0, 0, kartkaCur.current.width, kartkaCur.current.height);
		ctx.beginPath();
		ctx.fillStyle = tmpKol;
		ctx.arc(kartkaCur.current.width/2, kartkaCur.current.height/2, tmpGrub/2.2, 0, 2*Math.PI);
		ctx.fill();
		kartka.current.style.cursor = `url(${kartkaCur.current.toDataURL()}) ${tmpGrub*1.25 / 2} ${tmpGrub*1.2 / 2}, auto`;
	}

	return(
		<div className="brudnopis">
			<div className="container">
				<canvas
					id="kartka"
					willreadfrequently="true"
					ref={ustawReferencje}
					onMouseMove={(e) => polozenieMyszy(e)}
					onMouseDown={(e) => fWcisniety(e)}
					onMouseLeave={(e) => {
						fPuszczony(e);
						setAktWspolrzedne({x: -1, y: -1})
					}}
					onMouseUp={(e) => fPuszczony(e)}
					onResize={(e) => ustawReferencje(e)}
					onContextMenu={(e) => { e.preventDefault(); }}
				/>
				<canvas id="generujKursor" ref={ustawReferencjeGen} />
			</div>
            { props.rysujacy ?
			<div className="przybornik">
				<div className="wiersz2">
					<div className="wybraneKolory" style={{borderTop: `50px solid ${opcje.kolor}`, borderRight: `50px solid ${opcje.kolor2}`}} />
					<div className="paletaKolory">
						<div className="wiersz">
							<div className="kolorWybor" style={{backgroundColor: '#ffffff'}} onClick={() => { zmienPedzel("#ffffff", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#ffffff", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#c1c1c1'}} onClick={() => { zmienPedzel("#c1c1c1", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#c1c1c1", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#ef130b'}} onClick={() => { zmienPedzel("#ef130b", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#ef130b", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#ff7100'}} onClick={() => { zmienPedzel("#ff7100", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#ff7100", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#ffe400'}} onClick={() => { zmienPedzel("#ffe400", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#ffe400", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#00CC00'}} onClick={() => { zmienPedzel("#00CC00", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#00CC00", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#00FF91'}} onClick={() => { zmienPedzel("#00FF91", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#00FF91", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#00B2FF'}} onClick={() => { zmienPedzel("#00B2FF", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#00B2FF", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#231FD3'}} onClick={() => { zmienPedzel("#231FD3", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#231FD3", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#A300BA'}} onClick={() => { zmienPedzel("#A300BA", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#A300BA", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#DF69A7'}} onClick={() => { zmienPedzel("#DF69A7", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#DF69A7", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#FFAC8E'}} onClick={() => { zmienPedzel("#FFAC8E", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#FFAC8E", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#A0522D'}} onClick={() => { zmienPedzel("#A0522D", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#A0522D", true) }} />
						</div>
						<div className="wiersz">
							<div className="kolorWybor" style={{backgroundColor: '#000000'}} onClick={() => { zmienPedzel("#000000", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#000000", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#505050'}} onClick={() => { zmienPedzel("#505050", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#505050", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#740B07'}} onClick={() => { zmienPedzel("#740B07", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#740B07", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#C23800'}} onClick={() => { zmienPedzel("#C23800", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#C23800", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#E8A200'}} onClick={() => { zmienPedzel("#E8A200", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#E8A200", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#004619'}} onClick={() => { zmienPedzel("#004619", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#004619", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#00785D'}} onClick={() => { zmienPedzel("#00785D", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#00785D", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#00569E'}} onClick={() => { zmienPedzel("#00569E", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#00569E", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#0E0865'}} onClick={() => { zmienPedzel("#0E0865", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#0E0865", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#550069'}} onClick={() => { zmienPedzel("#550069", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#550069", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#873554'}} onClick={() => { zmienPedzel("#873554", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#873554", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#CC774D'}} onClick={() => { zmienPedzel("#CC774D", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#CC774D", true) }} />
							<div className="kolorWybor" style={{backgroundColor: '#63300D'}} onClick={() => { zmienPedzel("#63300D", false) }} onContextMenu={(e) => { e.preventDefault(); zmienPedzel("#63300D", true) }} />
						</div>
					</div>
					<div className="wyborGrubosci" onClick={() => setWysunGrubosc(!wysunGrubosc)} >
						<div className="gruboscPedzla" style={{width: opcje.grubosc*1.25, height: opcje.grubosc*1.2}} />
						{wysunGrubosc ? dostepneGrubosci() : ""}
					</div>
				</div>
				
				<div className="wiersz2">
					<div className={`uniwersalnyBox ${opcje.tryb == "pedzel" ? "wybrane" : ""}`}
						onClick={(e) => setOpcje({...opcje, tryb: "pedzel"})}
					>
						<HiPaintBrush />
					</div>
					<div className={`uniwersalnyBox ${opcje.tryb == "wiaderko" ? "wybrane" : ""}`}
						onClick={(e) => setOpcje({...opcje, tryb: "wiaderko"})}
					>
						<RiPaintFill style={{transform: 'scaleX(-1)'}}/>
					</div>
				</div>
				<div className="uniwersalnyBox" onClick={ () => czyscCanvas() } >
					<IoTrashBin />
				</div>
			</div>
            : "" }
		</div>
	);
};