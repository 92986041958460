import Autentykacja from "../Komponenty/Autentykacja";
import Rysowanie from "../Komponenty/Rysowanie";
import { useState, useRef, useEffect } from "react";

export default function BrudnopisTest(props){
	const [test, setTest] = useState({rysujacy: false, idpokoju: "brudnopis"});

	return(
        <>
        <Autentykacja nawigacja={true}/>
		<Rysowanie rysujacy={test.rysujacy} idpokoju={"brudnopis"}/>
		<button onClick={() => setTest({...test, rysujacy: !test.rysujacy})}>{test.rysujacy ? "Zostań zgadującym" : "Zostań rysującym"}</button>
        </>
	);
};