import { useState } from "react";
import Axios from "axios";
import globalneZmienne from "../globalneZmienne.js";
export default function Rejestracja(props){
	const [daneFormularza, setDaneFormularza] = useState({login: undefined, email: undefined, haslo: undefined, haslo2: undefined, etap: 1});

	const zarejestruj = () => {
		if(!daneFormularza.login){
            setDaneFormularza({...daneFormularza, blad: "Podaj swój login!"});
            return;
        }
		if(!daneFormularza.email){
			setDaneFormularza({...daneFormularza, blad: "Podaj swój email!"});
			return;
		}
        if(!daneFormularza.haslo){
            setDaneFormularza({...daneFormularza, blad: "Podaj swoje hasło!"});
            return;
        }
		if(!daneFormularza.haslo2){
            setDaneFormularza({...daneFormularza, blad: "Musisz powtórzyć hasło!"});
            return;
        }
		if(daneFormularza.haslo != daneFormularza.haslo2){
			setDaneFormularza({...daneFormularza, blad: "Wprowadzone hasła nie są jednakowe."});
			return;
		}
        if(daneFormularza.login.length < 5 || daneFormularza.login.length > 40){
            setDaneFormularza({...daneFormularza, blad: "Niepoprawna długość loginu!"});
            return;
        }
        if(daneFormularza.haslo.length < 6 || daneFormularza.haslo.length > 40){
            setDaneFormularza({...daneFormularza, blad: "Niepoprawna długość hasła!"});
            return;
        }
        Axios.post(globalneZmienne.adresBackend+"rejestracja", {login: daneFormularza.login, haslo: daneFormularza.haslo, email: daneFormularza.email})
        .then((r) => {
            if(!r.data['blad']){
				setDaneFormularza({...daneFormularza, blad: null, etap: 2});
            } else {
                setDaneFormularza({...daneFormularza, blad: r.data['blad']});
            }
        }).catch((er) => {
            setDaneFormularza({...daneFormularza, blad: er.message});
        })
	};

	const zweryfikuj = () => {
		if(!daneFormularza.kodZwrotny){
			setDaneFormularza({...daneFormularza, blad: "Musisz podać kod zwrotny! Wysłano go na twój e-mail."});
			return;
		}
		if(daneFormularza.kodZwrotny.length != 40){
			setDaneFormularza({...daneFormularza, blad: "Podano niepoprawny kod zwrotny..."});
			return;
		}
		Axios.post(globalneZmienne.adresBackend+"potwierdzRejestracje", {
			login: daneFormularza.login,
			haslo: daneFormularza.haslo,
			email: daneFormularza.email,
			kodWer: daneFormularza.kodZwrotny
		}).then((r) => {
			if(!r.data['blad']){
				setDaneFormularza({...daneFormularza, blad: null, etap: 3});
			} else {
				setDaneFormularza({...daneFormularza, blad: r.data['blad']});
			}
		}).catch((er) => {
			console.log(er);
			setDaneFormularza({...daneFormularza, blad: er.message});
		});
	};

    return(
	<>
		<div className="logowanieOkno">
			<h1>Rejestracja</h1>
			{ (daneFormularza.etap == 1) &&
			<>
				<span>Login</span>
				<input type="text" placeholder="Użytkownik" value={daneFormularza.login ? daneFormularza.login : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, login: e.target.value})}/>
				<span>E-Mail</span>
				<input type="text" placeholder="twoj@email.pl" value={daneFormularza.email ? daneFormularza.email : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, email: e.target.value})}/>
				<span>Hasło</span>
				<input type="password" placeholder="Hasło" value={daneFormularza.haslo ? daneFormularza.haslo : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, haslo: e.target.value})}/>
				<input type="password" placeholder="Powtórz hasło" value={daneFormularza.haslo2 ? daneFormularza.haslo2 : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, haslo2: e.target.value})}/>
				<button onClick={() => zarejestruj()}>Zarejestruj</button>
				{daneFormularza.blad && <p>{daneFormularza.blad}</p>}
				<div className="poziomaKreska" />
				<span>Masz konto?</span>
				<button onClick={() => { window.location.href="./zaloguj" }}>Zaloguj</button>
			</>}
			{ (daneFormularza.etap == 2) && 
			<>
				<span>Kod zwrotny</span>
				<input type="text" placeholder="Wysłano na e-mail" value={daneFormularza.kodZwrotny ? daneFormularza.kodZwrotny : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, kodZwrotny: e.target.value})}/>
				<button onClick={() => zweryfikuj()}>Zakończ</button>
				{daneFormularza.blad && <p>{daneFormularza.blad}</p>}
				<div className="poziomaKreska" />
				<span>Masz konto?</span>
				<button onClick={() => { window.location.href="./zaloguj" }}>Zaloguj</button>
			</>}
			{ (daneFormularza.etap == 3) && <>
				<span>Pomyślnie założono konto na naszej stronie!</span>
				<span>Możesz teraz się zalogować.</span>
				<button onClick={() => { window.location.href="./zaloguj" }}>Zaloguj</button>
			</>}
		</div>
	</>
	);
};