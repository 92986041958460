import globalneZmienne from "../globalneZmienne"
import Axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Autentykacja from "../Komponenty/Autentykacja";

export default function Profil(props){
    const [ daneProfilu, setDaneProfilu ] = useState({wlasny: false, urlCheck: false, zczytane: false, edytor: false, prevLink: window.location.href});
    const [ edycjaProfil, setEdycja ] = useState({usunAwatar: false, bio: null, email: null, powiadomienie: ""});
    let parametryUrl = useParams();

    const resetAll = () => {
        setDaneProfilu({wlasny: false, urlCheck: false, zczytane: false, edytor: false, prevLink: window.location.href});
    };

    const usunAwatar = () => {
        Axios.post(globalneZmienne.adresBackend+"usunAwatar/"+localStorage.getItem('token')).then((r) => {
            localStorage.setItem('awatar', '/img/awatary/default.png');
            setEdycja({...edycjaProfil, usunAwatar: false, powiadomienie: "Pomyślnie usunięto awatar!"});
        }).catch((er) => {
            setEdycja({...edycjaProfil, usunAwatar: false, powiadomienie: "Wystąpił błąd z usunięciem awatara"});
        });
    };

    const sprawdzUrl = () => {
		//sprawdzic zeby przydzielic wlasciwosc 'wlasny' ktora bedzie odpowiadac za wyswietlanie panelu edycji profilu
        if(!parametryUrl.uzytkownik){
            setDaneProfilu({...daneProfilu, wlasny: true, urlCheck: true, login: localStorage.getItem("login"), prevLink: window.location.href});
        } else {
            if(parametryUrl.uzytkownik == localStorage.getItem("login")){
                setDaneProfilu({...daneProfilu, wlasny: true, urlCheck: true, login: parametryUrl.uzytkownik, prevLink: window.location.href});
            } else {
                setDaneProfilu({...daneProfilu, wlasny: false, urlCheck: true, login: parametryUrl.uzytkownik, prevLink: window.location.href});
            }
        }
    };

    const wczytajDane = () => {
        Axios.post(globalneZmienne.adresBackend+"profilDane", {kogo: daneProfilu.login})
        .then((r) => {
            console.log(r.data);
            if(r.data['blad']){
                setDaneProfilu({...daneProfilu, zczytane: true, blad: "Błąd wczytywania danych profilu..."});
            } else {
                if(daneProfilu.wlasny){
                    setEdycja({...edycjaProfil, opis: r.data['opis']});
                }
                setDaneProfilu({...daneProfilu, zczytane: true, ...r.data});
            }
        }).catch((er) => {
            setDaneProfilu({...daneProfilu, zczytane: true, blad: er.message});
        });
    };

    const wgrajAwatar = () => {
        Axios.post(globalneZmienne.adresBackend+"zmienAwatar/"+localStorage.getItem("token"), {
            awatarImg: edycjaProfil.awatarPlik
        }, { headers: { 'Content-Type': 'multipart/form-data'}}).then((r) => {
            if(r.data['blad']){
                setEdycja({...edycjaProfil, plikNazwa: null, awatarPlik: null, awatarBlob: null, powiadomienie: "Wystąpił błąd podczas zmiany awatara!"});
            } else {
                localStorage.setItem('awatar', '/img/'+r.data['odp']);
                setEdycja({...edycjaProfil, plikNazwa: null, awatarPlik: null, awatarBlob: null, powiadomienie: "Pomyślnie zmieniono awatar!"});
                setDaneProfilu({...daneProfilu, awatar: r.data['odp']});
            }
        }).catch((er) => {
            setEdycja({...edycjaProfil, plikNazwa: null, awatarPlik: null, awatarBlob: null, powiadomienie: "Wystąpił błąd podczas zmiany awatara!"});
        });
    }

    const zmienHaslo = () => {
        if(!edycjaProfil.aktHaslo || !edycjaProfil.noweHaslo || !edycjaProfil.noweHaslo2) {
            setEdycja({...edycjaProfil, powiadomienie: "Wypełnij formularz poprawnie!"});
            return;
        }
        if(edycjaProfil.noweHaslo.length < 6 || edycjaProfil.noweHaslo.length > 40){
            setEdycja({...edycjaProfil, powiadomienie: "Niepoprawna długość nowego hasła!"});
            return;
        }
        if(edycjaProfil.noweHaslo != edycjaProfil.noweHaslo2){
            setEdycja({...edycjaProfil, powiadomienie: "Powtórzenie nowego hasła różni się!"});
            return;
        }
        Axios.post(globalneZmienne.adresBackend+"zmienHaslo/"+localStorage.getItem('token'), {
            aktHaslo: edycjaProfil.aktHaslo,
            noweHaslo: edycjaProfil.noweHaslo
        }).then((r) => {
            if(r.data['blad']){
                setEdycja({...edycjaProfil, powiadomienie: `Wystąpił błąd! ${r.data['blad']}`, noweHaslo: "", noweHaslo2: "", aktHaslo: ""});
            } else {
                setEdycja({...edycjaProfil, powiadomienie: "Pomyślnie zmieniono hasło!", noweHaslo: "", noweHaslo2: "", aktHaslo: ""});
            }
        }).catch((er) => {
            setEdycja({...edycjaProfil, powiadomienie: "Wystąpił błąd! Hasło niezmienione!", noweHaslo: "", noweHaslo2: "", aktHaslo: ""});
        });
    };

    const usunKonto = () => {
        Axios.post(globalneZmienne.adresBackend+"usunKontoSmutek/"+localStorage.getItem("token"), {
            login: localStorage.getItem("login")
        }).then((r) => {
            if(!r.data['blad']){
                localStorage.clear();
                window.location.reload();
            } else {
                setEdycja({...edycjaProfil, powiadomienie: "Wystąpił błąd z usunięciem konta!"});
            }
        }).catch((er) => {
            setEdycja({...edycjaProfil, powiadomienie: "Wystąpił błąd z usunięciem konta!"});
        });
    };

    const zmienOpis = () => {
        Axios.post(globalneZmienne.adresBackend+"zmienOpisProfil/"+localStorage.getItem("token"), {
            opis: edycjaProfil.opis
        }).then((r) => {
            if(!r.data['blad']){
                setEdycja({...edycjaProfil, powiadomienie: "Pomyślnie zmieniono opis profilu!"});
                setDaneProfilu({...daneProfilu, opis: edycjaProfil.opis});
            } else {
                setEdycja({...edycjaProfil, powiadomienie: `Wystąpił błąd ze zmiany opisu! ${r.data['blad']}`});
            }
        }).catch((er) => {
            setEdycja({...edycjaProfil, powiadomienie: "Wystąpił błąd sieciowy!"});
        });
    }

    const wyswietlEdytor = () => {
        return(
            <div className="edytorProfilu">
                <div className="edycjaAwatar">
                    <div className="edycjaAwatarBlob"  style={globalneZmienne.obejscieTlo(edycjaProfil.awatarBlob ? edycjaProfil.awatarBlob : localStorage.getItem('awatar'))}/>
                    <div className="opcjeEdycjiAwatara">
                        <h3>Awatar</h3>
                        { (daneProfilu.awatar != "awatary/default.png") ?
                            (edycjaProfil.usunAwatar ?
                                (
                                    <div style={{gap: '10px', display: 'flex'}}>
                                        <button className="btnBad" onClick={(e) => setEdycja({...edycjaProfil, powiadomienie: "", usunAwatar: false}) }>Anuluj</button>
                                        <button className="btnGood" onClick={(e) => usunAwatar()}>Potwierdź</button>
                                    </div>
                                ) :
                                <button onClick={(e) => setEdycja({...edycjaProfil, powiadomienie: "", usunAwatar: true}) }>Usuń awatar</button>
                            )
                        : ""}
                        <div style={{gap: '10px', display: 'flex'}}>
                            <label htmlFor="blobAwatar" style={{cursor: "pointer"}} className={edycjaProfil.plikNazwa ? "uploadZdjeciaTak" : "uploadZdjeciaNie"} >{edycjaProfil.plikNazwa ? "Wybrano plik..." : "Nowy awatar"}</label>
                            {edycjaProfil.plikNazwa ?
                            <div style={{gap: '10px', display: 'flex'}}>
                                <button className="btnBad" onClick={() => setEdycja({...edycjaProfil, powiadomienie: "", plikNazwa: null, awatarPlik: null, awatarBlob: null})}>Anuluj</button>
                                <button className="btnGood" onClick={() => wgrajAwatar()}>Potwierdź</button>
                            </div>
                            : ""}
                            <input type="file" id="blobAwatar"
                                onChange={(e) => { 
                                    setEdycja({...edycjaProfil, powiadomienie: "", plikNazwa: e.target.value, awatarPlik: e.target.files[0], awatarBlob: URL.createObjectURL(e.target.files[0])});
                                }}
                                accept="image/png, image/jpeg"
                                hidden={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="edycjaBio">
                    <h3>Opis profilu</h3>
                    <textarea
                        value={edycjaProfil.opis ? edycjaProfil.opis : ''} placeholder={`Niestety użytkownik ${daneProfilu.login ? daneProfilu.login : ""} nie posiada ustawionego opisu profilu, ale wierzymy, że jest całkiem spoko!`}
                        onChange={(e) => setEdycja({...edycjaProfil, powiadomienie: "", opis: e.target.value})}
                    />
                    <button onClick={() => {setEdycja({...edycjaProfil, powiadomienie: ""}); zmienOpis();}}>Zmień opis</button>
                </div>
                <div className="edycjaInne">
                    <div className="edycjaHaslo">
                            <h3>Zmiana hasła</h3>
                            <input type="password" placeholder="Aktualne hasło" value={edycjaProfil.aktHaslo ? edycjaProfil.aktHaslo : ""} onChange={(e) => setEdycja({...edycjaProfil, powiadomienie: "", aktHaslo: e.target.value})} />
                            <input type="password" placeholder="Nowe hasło" value={edycjaProfil.noweHaslo ? edycjaProfil.noweHaslo : ""} onChange={(e) => setEdycja({...edycjaProfil, powiadomienie: "", noweHaslo: e.target.value})} />
                            <input type="password" placeholder="Powtórz nowe" value={edycjaProfil.noweHaslo2 ? edycjaProfil.noweHaslo2 : ""} onChange={(e) => setEdycja({...edycjaProfil, powiadomienie: "", noweHaslo2: e.target.value})}/>
                            { edycjaProfil.potwierdzHaslo ?
                                <div style={{gap: '10px', display: 'flex', justifyContent: 'space-between'}}>
                                    <button className="btnBad" style={{flexGrow: 1}} onClick={() => setEdycja({...edycjaProfil, powiadomienie: "", potwierdzHaslo: false})}>Anuluj</button>
                                    <button className="btnGood" onClick={() => zmienHaslo()}>Potwierdź</button>
                                </div>
                                :
                                <button onClick={() => setEdycja({...edycjaProfil, powiadomienie: "", potwierdzHaslo: true})}>Zmień</button>
                            }
                    </div>

                    <div className="usunKonto">
                        <h3>Usuwanie konta (!)</h3>
                        { edycjaProfil.potwierdzUsun ?
                            <div style={{gap: '10px', display: 'flex'}}>
                                <button className="btnBad" onClick={() => setEdycja({...edycjaProfil, powiadomienie: "", potwierdzUsun: false})}>Anuluj</button>
                                <button className="btnGood" onClick={() => usunKonto()}>Potwierdź</button>
                            </div>
                            :
                            <button onClick={() => setEdycja({...edycjaProfil, powiadomienie: "", potwierdzUsun: true})}>Usuń konto!</button>
                        }
                    </div>
                </div>
                {edycjaProfil.powiadomienie ? <span>{edycjaProfil.powiadomienie}</span> : ""}
            </div>
        )
    };

    const wyswietlDane = () => {
        return(
            <div className="wycentrujPion">
                <div className="profilUzytkownika">
                    <div className="profilGora">
                        <div className="profilAwatar" style={{background: `#222 url(${'/img/'+daneProfilu.awatar})`}} />
                        <div className="profilDane">
                            <h1>{!daneProfilu.blad ? daneProfilu.login : "Nieistniejące konto"}</h1>
                            <h5>Konto założone {daneProfilu.dataRejestracji ? new Date(daneProfilu.dataRejestracji).toLocaleString('pl-PL', {day: '2-digit', month: 'long', year: 'numeric'}) : "???"}</h5>
                            <h5>Rozegranych gier: {daneProfilu.rozegraneGry ? daneProfilu.rozegraneGry : "???"}</h5>
                            <div className="przerywnik" />
                            <h3>Opis profilu</h3>
                            <textarea
                                value={daneProfilu.opis ? daneProfilu.opis : `Niestety użytkownik ${daneProfilu.login ? daneProfilu.login : ""} nie posiada ustawionego opisu profilu, ale wierzymy, że jest całkiem spoko!`}
                                onChange={(e) => setDaneProfilu({...daneProfilu, opis: e.target.value})}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="wykresyProfilu">
                        <h3>Tutaj będą jakieś wykresy profilu z statystyki rozgrywek</h3>
                    </div>
                    { daneProfilu.wlasny ? <button className="otworzEdytor" onClick={(e) => setDaneProfilu({...daneProfilu, edytor: !daneProfilu.edytor})}>{daneProfilu.edytor ? "Anuluj" : "Edycja"}</button> : "" }
                    { daneProfilu.edytor ? wyswietlEdytor() : "" }
                </div>
            </div>
        )
    }

    return(
        <>
            <Autentykacja nawigacja={true}/>
            { !daneProfilu.urlCheck ? sprawdzUrl() : ( !daneProfilu.zczytane ? wczytajDane() : wyswietlDane() ) }
            { (!daneProfilu.prevLink || (daneProfilu.prevLink !== window.location.href)) && resetAll() }
        </>
    )
};