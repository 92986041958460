import { useState } from "react";
import Axios from "axios";
import globalneZmienne from "../globalneZmienne";

export default function ResetHasla(props){
    const [ daneFormularza, setDaneFormularza ] = useState({login: null, zwrotny: null, haslo1: null, haslo2: null, blad: null, etap: 1});



    const sprawdzLogin = () => {
        if(!daneFormularza.login){
            setDaneFormularza({...daneFormularza, blad: "Podaj login!"});
            return;
        }
        if(daneFormularza.login.length > 40 || daneFormularza.login.length < 4){
            setDaneFormularza({...daneFormularza, blad: "Niepoprawna długość loginu."});
            return;
        }
        Axios.post(globalneZmienne.adresBackend+"reset", {
            username: daneFormularza.login
        }).then((r) => {
            if(r.data['blad']){
                setDaneFormularza({...daneFormularza, blad: r.data['blad']})
            } else {
                setDaneFormularza({...daneFormularza, etap: 2, blad: null});
            }
        }).catch((er) => {
            console.log(er);
            setDaneFormularza({...daneFormularza, blad: er.message});
        });
    };

	const etap1 = () => {
		return(
		<div className="logowanieOkno">
            <h1>Resetowanie hasła</h1>
            <span>Login</span>
            <input type="text" placeholder="Użytkownik" value={daneFormularza.login ? daneFormularza.login : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, login: e.target.value})}/>
            <button onClick={() => sprawdzLogin()}>Dalej</button>
            {daneFormularza.blad && <p>{daneFormularza.blad}</p>}
            <div className="poziomaKreska" />
            <span>Jednak pamiętam!</span>
            <button onClick={() => { window.location.href="./zaloguj" }}>Zaloguj</button>
        </div>
		);
	};

	const sprawdzZwrotny = () => {
        if(!daneFormularza.zwrotny){
            setDaneFormularza({...daneFormularza, blad: "Podaj kod zwrotny!"});
            return;
        }
        if(daneFormularza.zwrotny.length != 40){
            setDaneFormularza({...daneFormularza, blad: "Niepoprawny kod zwrotny."});
            return;
        }
        Axios.post(globalneZmienne.adresBackend+"resetcheck", {
            kodzik: daneFormularza.zwrotny
        }).then((r) => {
            if(r.data['blad']){
                setDaneFormularza({...daneFormularza, blad: r.data['blad']})
            } else {
                setDaneFormularza({...daneFormularza, etap: 3, blad: null});
            }
        }).catch((er) => {
            console.log(er);
            setDaneFormularza({...daneFormularza, blad: er.message});
        });
    };

	const zmienHaslo = () => {
        if(!daneFormularza.haslo1){
            setDaneFormularza({...daneFormularza, blad: "Podaj nowe hasło!"});
            return;
        }
        if(daneFormularza.haslo1.length > 60 || daneFormularza.haslo1.length < 5){
            setDaneFormularza({...daneFormularza, blad: "Niepoprawna długość hasła."});
            return;
        }
		if(!daneFormularza.haslo2){
			setDaneFormularza({...daneFormularza, blad: "Powtórz swoje hasło!"});
			return;
		}
		if(daneFormularza.haslo1 != daneFormularza.haslo2){
			setDaneFormularza({...daneFormularza, blad: "Podane hasła różnią się."});
			return;
		}
        Axios.post(globalneZmienne.adresBackend+"resetfinal", {
            kodzwrotny: daneFormularza.zwrotny,
			haslo: daneFormularza.haslo1
        }).then((r) => {
            if(r.data['blad']){
                setDaneFormularza({...daneFormularza, blad: r.data['blad']})
            } else {
                setDaneFormularza({...daneFormularza, etap: 4, blad: null});
            }
        }).catch((er) => {
            console.log(er);
            setDaneFormularza({...daneFormularza, blad: er.message});
        });
    };

	const etap2 = () => {
		return(
			<div className="logowanieOkno">
				<h1>Resetowanie hasła</h1>
				<span>Kod zwrotny</span>
				<input type="text" placeholder="Wysłano na e-mail" value={daneFormularza.zwrotny ? daneFormularza.zwrotny : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, zwrotny: e.target.value})}/>
				<button onClick={() => sprawdzZwrotny()}>Dalej</button>
				{daneFormularza.blad && <p>{daneFormularza.blad}</p>}
				<div className="poziomaKreska" />
				<span>Jednak pamiętam!</span>
				<button onClick={() => { window.location.href="./zaloguj" }}>Zaloguj</button>
			</div>
		);
	};

	const etap3 = () => {
		return(
			<div className="logowanieOkno">
				<h1>Resetowanie hasła</h1>
				<span>Już prawie! Ustaw swoje nowe hasło!</span>
				<span>Nowe hasło</span>
				<input type="password" placeholder="Nowe hasło" value={daneFormularza.haslo1 ? daneFormularza.haslo1 : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, haslo1: e.target.value})}/>
				<span>Powtórz hasło</span>
				<input type="password" placeholder="Powtórz hasło" value={daneFormularza.haslo2 ? daneFormularza.haslo2 : ""} onChange={(e) => setDaneFormularza({...daneFormularza, blad: undefined, haslo2: e.target.value})}/>
				<button onClick={() => zmienHaslo()}>Zakończ</button>
				{daneFormularza.blad && <p>{daneFormularza.blad}</p>}
				<div className="poziomaKreska" />
				<span>Jednak pamiętam!</span>
				<button onClick={() => { window.location.href="./zaloguj" }}>Zaloguj</button>
			</div>
		);
	};

	const etap4 = () => {
		return(
			<div className="logowanieOkno">
				<h1>Resetowanie hasła</h1>
				<span>Pomyślnie zmieniono twoje hasło!</span>
				<button onClick={() => { window.location.href="./zaloguj" } }>Zaloguj</button>
			</div>
		)
	};

    return(
        <>
        {(daneFormularza.etap == 1) && etap1() }
		{(daneFormularza.etap == 2) && etap2() }
		{(daneFormularza.etap == 3) && etap3() }
		{(daneFormularza.etap == 4) && etap4() }
    	</>
    );
};