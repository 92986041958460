import Autentykacja from "../Komponenty/Autentykacja";
import { io } from "socket.io-client";
import globalneZmienne from "../globalneZmienne";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import Czat from "../Komponenty/Czat";
import Rysowanie from "../Komponenty/Rysowanie";
//import { SourceTextModule } from "vm";

export default function Rozgrywka(props){
	var socket=io.connect(globalneZmienne.adresBackend);
	let parametryUrl = useParams();
	const [listaUczestnikow,setListauczestnikow]=useState({zawartosc:null});
	let gotowi=[false];
	let usrPunkty=[0];
	let rysuRysu=null;
	const [buttonText,setButtonText]=useState("Gotowy");
	const [jakieSlowo,setJakieSlowo]=useState("");
	let ludzie=[];
	const [start,setStart]=useState(false);
	const [timer,setTimer]=useState(0);
	const [rysujacy,setRysujacy]=useState(false);
	const [rysownik,setRysownik]=useState(null);
	const [zgadniete,setZgadniete]=useState(false);

	useEffect(() => {
		socket.on("connect",()=>{
			console.log(socket.id);
			socket.emit('nowyPokoj',{url:parametryUrl.kodPokoju,login:localStorage.getItem("login")});
		});

		socket.on("dolaczono_pokoj",(e)=>{
			let uniq=[... new Set(e.gracze)];
			uniq.sort();
			gotowi.push(false);
			usrPunkty.push(0);
			ludzie=(e.gracze);
			setListauczestnikow({zawartosc:e.gracze,ready:gotowi,punkty:usrPunkty});
		});

		socket.on("gotowy", kto => {
			console.log(ludzie);
			if(ludzie.length!=gotowi.length) gotowi.pop();
			if(ludzie.length!=usrPunkty.length) usrPunkty.pop();
			let indeksGracza=ludzie.findIndex(wartosc=>{ return wartosc==kto });
			if(indeksGracza>-1){
				gotowi[indeksGracza]=!gotowi[indeksGracza];
				setListauczestnikow({zawartosc:ludzie,ready:gotowi,punkty:usrPunkty});
			}
		});
		
		socket.on("start", () => {
			setStart(true);
			socket.emit("dejSlowo", parametryUrl.kodPokoju);
			console.log("start");
		});

		socket.on("timerStart", czas => {
			socket.emit("czyscCanvas2", {idpokoju: parametryUrl.kodPokoju});
			setTimer(parseInt(czas));
		});

		socket.on("rysujesz", () => {
			setRysujacy(true);
			//generujSlowo();
			socket.emit("dejSlowo",parametryUrl.kodPokoju);
			console.log("ty rysujesz");
		});

		socket.on("maszSlowo", slowo => {
			console.log(slowo);
			console.log(slowo.Slowo);
			setJakieSlowo(slowo.Slowo);
			socket.emit("cenzuruj",{slowo:slowo.Slowo,id:parametryUrl.kodPokoju});
		});

		socket.on("zacenzurowane", slowoCenz => { setJakieSlowo(slowoCenz) });

		socket.on("tenRysuje", login => {
			setRysownik(login);
			console.log(rysownik);
			console.log(login);
			rysuRysu=login;
		});

		socket.on("resetRysujacy", () => {
			setRysujacy(false);
			setZgadniete(false);
		});

		socket.on("zgadles", e => {
			console.log(e);
			console.log("rysownik", rysownik);
			if(e!=rysuRysu) {
				console.log(e);
				console.log("rysownik");
				console.log(rysownik);
				usrPunkty[ludzie.findIndex(x=>{return e==x})]+=1;
				console.log("jest zgadniete");
				setZgadniete(true);
				setTimer(0);
				nextRunda();
			}
		});

	}, []);

	useEffect(() => {
		if(!zgadniete && timer > 0){
			const interwal = setInterval(() => setTimer(timer - 1), 1000);
			return () => clearInterval(interwal);
		}
	}, [timer])

	const nextRunda = () => {
		console.log("czy next ");
		if(rysujacy) {
			socket.emit("nextRunda",{login:localStorage.getItem("login"),pokoj:parametryUrl.kodPokoju});
			console.log("nextRunda");
		}
	};

 
	// useEffect(()=>{
	// 	(zgadniete==false && timer>0) && setTimeout( () => setTimer(timer-1), 1000);
	// }, [timer]);

	const wyswietlUzytkownikow = () => {
		if(listaUczestnikow.zawartosc){
			//console.log(listaUczestnikow)
			return listaUczestnikow.zawartosc.map((gracz, got) =>{
				//let zm=gotowi.findIndex(e=>{return e==gracz});
				return(
					<div className="uczestnik">
						<b>{gracz}</b>
						<input type="button" disabled={listaUczestnikow.zawartosc.length>1?false:true} hidden={(gracz==localStorage.getItem("login")&&!start)?false:true}  onClick={()=>readyCheck()} value={buttonText}></input>
						<label hidden={(start&&gracz==rysownik)?false:true}>Rysuje</label>
						<label hidden={((gracz==localStorage.getItem("login"))||start)?true:false}>{listaUczestnikow.ready[got]?"gotowy":"nie gotowy"}</label>
						<label style={{width: '60px'}} hidden={start?false:true}>{listaUczestnikow.punkty[got]+" pkt"}</label>
					</div>
				)
			})
		}
	};

	const readyCheck = () => {
		console.log("ready")
		if(buttonText=="Gotowy") {
			socket.emit("ready",{login:localStorage.getItem("login"),pokoj:parametryUrl.kodPokoju,gotowy:true})
			setButtonText("Nie gotowy")
		} else {	
			socket.emit("ready",{login:localStorage.getItem("login"),pokoj:parametryUrl.kodPokoju,gotowy:false})
			setButtonText("Gotowy")
		}
	};


	
	// const czyscliste=()=>{
	// 	//zmienić na socket bo axios resetuje połączenie
	// 	socket.emit("czyscListe",{kod:parametryUrl.kodPokoju});
	// 	socket.emit("dejListe",{kod:parametryUrl.kodPokoju});
		
			//setListauczestnikow({zawartosc:null})
			
			//dejliste();
		//socket.emit("lista",{login:localStorage.getItem("login"),pokoj:parametryUrl.kodPokoju})
	//}
	//const dejliste=()=>{
		

	// const updateListe=()=>{
		
	// }
	
	// socket.on('disconnect',()=>{
	// 	Axios.post(globalneZmienne.adresBackend+"opuszczonoPokoj",{kod: parametryUrl.kodPokoju,login:localStorage.getItem("login")}).then((res)=>{
	// 		console.log(res);
	// 	})
	 

	
    return(<>
	<Autentykacja nawigacja={true}/>
	
	<div className="rozgrywka">
	{/* <p>Rozgrywka</p>
       <span>Kod pokoju: {parametryUrl.kodPokoju}</span> */}
	   {/*<span>Lista uzytkownikow:{listaUczestnikow.zawartosc? listaUczestnikow.zawartosc:""}</span>*/}
		<div className="listaUczestnikow">
			{listaUczestnikow.zawartosc? wyswietlUzytkownikow():""}
		</div>
		<div className="srodek">
	   		<div className="naglowekRozgrywka">
				<div className="slowo">{jakieSlowo ? jakieSlowo : "Czekam na rozpoczęcie rundy..."}</div>
		   		<div className="licznik">{(timer==0 && rysujacy) ? nextRunda() : (timer.toString()+" sekund")}</div>
			</div>
			<Rysowanie rysujacy={rysujacy} idpokoju={parametryUrl.kodPokoju}/>
	   </div>
	   <Czat tytul={"Czat rozgrywki"} id={parametryUrl.kodPokoju} />
	   </div>
	  
    </>)
};