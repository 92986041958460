import Autentykacja from "../Komponenty/Autentykacja";
import { io } from "socket.io-client";
import globalneZmienne from "../globalneZmienne";
import { NavLink, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { BsCode, BsDot } from "react-icons/bs";
import Axios from "axios";
import Czat from "../Komponenty/Czat";
let socket = io.connect(globalneZmienne.adresBackend);

export default function Glowna(props){
    const [ dostepnePokoje, setDostepnePokoje ] = useState({response: false, dane: null, blad: null});
	const [ tworzeniePokoju, setTworzeniePokoju ] = useState({wysuniete: false,limitGraczy: "6",czasRysowania: "60",limitRund:"6", otwarty: "1",hotjoin :"0"});
	const [ ustawKod, setUstawKod]=useState({kod:null})
	/* TODO:
	
	- interwał odświeżający listę dostępnych pokoi
	- test tworzenie rozgrywki, dołączanie po kodzie i losowo

	Czat już jest jako komponent re-używalny jak coś.
	*/




		const dolaczPrzezKod=()=>{
			Axios.post(globalneZmienne.adresBackend+"dolaczPrzezKod/"+localStorage.getItem("token"),ustawKod)
				.then((res)=>{
					console.log(res.data)
					if(res.data)
					window.location.href="/rozgrywka/"+res.data;
				})
		}

        const nowyPokoj=()=>{
			//tylko tworzony, zrobić dodawanie do listy pokoi
				
				Axios.post(globalneZmienne.adresBackend+"stworzPokoj/"+localStorage.getItem("token"),{pokoj:tworzeniePokoju,login:localStorage.getItem("login")})
				.then((res)=>{
                window.location.href="/rozgrywka/"+res.data.klucz;})
            
        };
		const dolaczLosowo=()=>{
			Axios.post(globalneZmienne.adresBackend+"dolaczLosowyPokoj/"+localStorage.getItem("token")).then((res)=>{
				window.location.href="/rozgrywka/"+res.data;
			})
		}
       
    const wyswietlDostepnePokoje = () => {
		if(dostepnePokoje.dane && dostepnePokoje.dane.length){
			return dostepnePokoje.dane.map((pokoj, kluczArray) => {
				console.log(kluczArray, pokoj);
				const dolaczLista=()=>{
					window.location.href="/rozgrywka/"+pokoj.kodDolaczenia;
				}
				return(
					<div className="dostepnyPokoj">
						<b>{pokoj.nazwaPokoju}</b>
						<button onClick={()=>dolaczLista()}>Dołącz</button>
					</div>
				)
			})
		} else {
			return <p>Brak dostępnych pokoji</p>
		};
	};

	const dostanDostepnePokoje = () => {
		Axios.post(globalneZmienne.adresBackend+"dostepnePokoje/"+localStorage.getItem("token")).then((r) => {
			setDostepnePokoje({response: true, dane: r.data.odp, blad: null});
		}).catch((er) => {
			setDostepnePokoje({response: true, dane: null, blad: "Wystąpił błąd wczytywania dostępnych pokoji. Spróbuj jeszcze raz."});
		});
	};

	
	const wyswietlTworzeniePokoju = () => {
		return(
			<div className="tworzeniePokoju">
				<h2>Tworzenie pokoju</h2>

				<h4>Limit graczy:</h4>
				<input type="number" min="2" max="12" value={tworzeniePokoju.limitGraczy ? tworzeniePokoju.limitGraczy : 6} onChange={(e) => setTworzeniePokoju({...tworzeniePokoju, limitGraczy: e.target.value})} />

				<h4>Czas rysowania:</h4>
				<select value={tworzeniePokoju.czasRysowania ? tworzeniePokoju.czasRysowania : 60} onChange={(e) => setTworzeniePokoju({...tworzeniePokoju, czasRysowania: e.target.value})}>
					<option value={30}>30 sekund</option>
					<option value={45}>45 sekund</option>
					<option value={60}>60 sekund</option>
					<option value={90}>90 sekund</option>
					<option value={120}>120 sekund</option>
					<option value={150}>150 sekund</option>					
					<option value={180}>180 sekund</option>
					<option value={210}>210 sekund</option>
					<option value={240}>240 sekund</option>
				</select>
				
				<h4>Liczba rund:</h4>
				<input type="number" min="1" max="16" value={tworzeniePokoju.limitRund ? tworzeniePokoju.limitRund : 8} onChange={(e) => setTworzeniePokoju({...tworzeniePokoju, limitRund: e.target.value})} />

				<h4>Pokój otwarty:</h4>
				<select value={tworzeniePokoju.otwarty ? tworzeniePokoju.otwarty : true} onChange={(e) => setTworzeniePokoju({...tworzeniePokoju, otwarty: e.target.value})}>
					<option value={1}>Tak</option>
					<option value={0}>Nie</option>
				</select>
				
				<h4>Pozwól dołączać w trakcie:</h4>
				<select value={tworzeniePokoju.hotjoin ? tworzeniePokoju.hotjoin : 1} onChange={(e) => setTworzeniePokoju({...tworzeniePokoju, hotjoin: e.target.value})}>
					<option value={1}>Tak</option>
					<option value={0}>Nie</option>
				</select>

				<button onClick={() => setTworzeniePokoju({...tworzeniePokoju, wysuniete: false})}>Anuluj tworzenie</button>
				<button onClick={() => nowyPokoj()}>Utwórz pokój</button>
			</div>
		);
	};

    return(
        <>
        <Autentykacja nawigacja={true}/>
        {/* <h1>Glowna (po zalogowaniu)</h1>

        <button onClick={() => nowyPokoj()}>Utworz pokoj</button>
        
        <input type="text" id="tekstKod" placeholder="Dołącz po Kodzie" onChange={(e)=>ustawKod({kod:e.target.value})} ></input>
        <button  id="przyciskKod"  onClick={() => dolaczKod()}>Dolacz po kodzie</button>
        
        <button onClick={()=>listRooms()}>listRooms</button>
        <p  id="test" ></p> */}
		<div className="glowna">
			<div className="pokoje">
				<div className="gorne">
					<button onClick={() => setTworzeniePokoju({...tworzeniePokoju, wysuniete: true})}>Utwórz rozgrywke</button>
					
					<button onClick={()=>dolaczLosowo()}>Losowa rozgrywka</button>
					<div className="pokojeDolaczKod">
						<input type="text"  placeholder="Kod pokoju" onChange={(e)=>setUstawKod({kod:e.target.value})} ></input>
						<button onClick={() => dolaczPrzezKod()}>Dołącz</button>
					</div>
				</div>
				<div className="poziomaKreska" />
				<div className="dostepnePokoje">
					{dostepnePokoje.response ? wyswietlDostepnePokoje() : dostanDostepnePokoje() }
				</div>
				{tworzeniePokoju.wysuniete ? wyswietlTworzeniePokoju() : ""}
			</div>
			<Czat tytul="Czat globalny" id="globalny" />
		</div>
        </>
    );
};