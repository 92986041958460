import globalneZmienne from "../globalneZmienne";
import Axios from "axios";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { RiUser3Fill, RiLogoutBoxLine, RiHome3Fill } from "react-icons/ri";
import { MdDraw } from "react-icons/md";

export default function Autentykacja(props){
	const [ sesja, setSesja ] = useState(false);
	const sprawdzSesje = () => {
		if(localStorage.getItem("token")){
			Axios.post(globalneZmienne.adresBackend+"sprawdztoken/"+localStorage.getItem("token"))
			.then((r) => {
				if(r.data['login'] && localStorage.getItem("login") == r.data['login']){
					localStorage.setItem("awatar", "/img/"+r.data['awatar']);
					setSesja(true);
				} else {
					wyloguj();
				}
			}).catch((er) => {
				wyloguj();
			});
		} else {
			wyloguj();
		}
	};

	const wyloguj = () => {
		localStorage.clear();
		window.location.href = "/zaloguj";
	};

	const zawartoscHeadera = () => {
		return(
			<>
				<div className="headerAwatar" style={{background: `#4f4f4f url(${localStorage.getItem('awatar')})` }}/>
				<span>Cześć, <b>{localStorage.getItem("login")}</b>!</span>
				<div className="poziomaKreska" />
				<nav>
					<ul>
						<li>
							<NavLink to="/">
								<RiHome3Fill /> Główna
							</NavLink>
						</li>
						<li>
							<NavLink to="/profil">
								<RiUser3Fill /> Profil
							</NavLink>
						</li>
						<li>
							<NavLink to="/brudnopis">
								<MdDraw /> Brudnopis
							</NavLink>
						</li>
						<li>
							<NavLink to="/brudnopistest">
								<MdDraw /> BrudnopisTest
							</NavLink>
						</li>
					</ul>
					<button onClick={() => wyloguj()}><RiLogoutBoxLine /> Wyloguj</button>
				</nav>
			</>
		);
	};

	if(props.nawigacja){
		return(
			<header>
				{!sesja && sprawdzSesje() }
				{ sesja && zawartoscHeadera() }
			</header>
		);
	} else {
		return(<>{!sesja && sprawdzSesje() }</>);
	}
};